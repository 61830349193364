import React, {Component} from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import {Helmet} from "react-helmet";
import {graphql} from "gatsby";

class KontaktPage extends Component {
    render() {
        let offersData = this.props.data.allStrapiOfferPages.edges;
        return (
            <Layout page="kontakt">
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Kontaktieren Sie die Chatbot-Experten | Paixon</title>
                    <meta name="description"
                          content="Sie planen ein Voice- oder Chatbot-Projekt? Nehmen Sie noch heute unverbindlich mit uns Kontakt auf. Wir freuen uns Sie zu unterstützen."/>
                </Helmet>
                <Header className="contact" slogan={this.props.data.allStrapiKontaktPages.edges[0].node.slogan}
                        blogphoto={{backgroundImage: `url(${this.props.data.allStrapiKontaktPages.edges[0].node.photo.publicURL})`}}
                        offersData={offersData}/>
                <main className="container contact pt">
                    <div className="row">
                        <h1>Kontakt</h1>
                    </div>
                    <div className="row">
                        <div className="address">
                            <br/> Paixon GmbH
                            <br/> Neunbrunnenstrasse 31
                            <br/> 8050 Zürich
                            <br/>
                            <br/>
                            <span>T</span>{" "}
                            <a href="tel:+41786080313" title="+41786080313">+41 (0)78 608 03 13</a>
                            <br/>
                            <span>M</span>{" "}
                            <a href="mailto:info@paixon.ch" title="info@paixon.ch">
                                info@paixon.ch
                            </a>
                            <br/>
                            <span>W</span>{" "}
                            <a href="https://paixon.ch/" title="paixon.ch" target="_blank">paixon.ch</a>
                            <br/>
                        </div>
                    </div>

                    <div className='section-white'>
                        <h3>Gemeinsam ins Zeitalter der digitalen Kundenansprache?</h3>
                        <div className="offer-call-to-action-button action-button-element">
                            <a className="action-button"
                               href="mailto:info@paixon.ch">
                                <h3 className="action-button-text">Kostenloses Gespräch vereinbaren</h3>
                            </a>
                        </div>
                    </div>
                </main>
            </Layout>
        )
    }
}

export default KontaktPage

export const pageQuery = graphql`  
  query KontaktQuery {
    allStrapiKontaktPages {
    edges {
      node {
        slogan
        photo {
          publicURL
        }
      }
    }
  }
  allStrapiOfferPages {
        edges {
            node {
                title
                url
                order
            }
        }
    }
  }
`
